import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'
import React, {useState} from 'react'
import Swal from 'sweetalert2'
import CustomButton from '../CustomButton'
import RightArrIcon from '../Icons/RightArrIcon'
import axios from 'axios'
import {BURL, formatNum} from '../../services/base'
import {useDispatch, useSelector} from 'react-redux'
import CurrencyFormat from 'react-currency-format'
import {setTransactions} from '../../slices/userSlice'
import BankNoteIcon from '../Icons/BankNoteIcon'
import {toast} from 'react-toastify'

const RequestFund = ({open, setOpen, wallet}) => {
	const {cash_balance} = useSelector(state => state.profile);
	const [inProgress, setInProgress] = useState(false)
	const [amountFigure, setamountFigure] = useState(0);
	const [err, setErr] = useState(null);

	const successRes = () => {
		setOpen(false)
		Swal.fire({
			title: 'Request successful',
			icon: 'success',
			confirmButtonText: 'Continue',
		})
	}

	const dispatch = useDispatch();
	const submit = (e) => {
		e.preventDefault();
		if (!amountFigure) {
			setErr("Amount is required")
			return
		} else if (Number(amountFigure) > Number(wallet === 'naira' ? cash_balance?.customerNairaBalance : cash_balance?.customerDollarBalance)) {
			return
		};
		setErr(null)
		setInProgress(true);
		axios.post(`${BURL}request-${wallet}-balance`, {amount: Number(amountFigure)}).then(res => {
			if (!res?.data?.success) throw new Error(res);
			setInProgress(false);
			successRes();
			axios.get(`${BURL}customer-transactions-table`).then(res => {
				if (!res?.data?.success) throw new Error(res);
				dispatch(setTransactions(res.data.message))
			})
		}).catch(err => {
			setInProgress(false);
			if (err?.response?.data) {
				toast.error(err?.response?.data?.message)
			} else {
				toast.error("Not connected, please try again.")
			}
		})
	}

	const handleChange = (values) => {
		setamountFigure(values.value);
		if (Number(values.value) > Number(wallet === 'naira' ? cash_balance?.customerNairaBalance : cash_balance?.customerDollarBalance)) {
			setErr("You can't request more than your balance")
		} else {
			setErr(null)
		};
	}

	return (
		<>
			<Dialog fullWidth maxWidth={'sm'} PaperProps={{sx: {maxWidth: 480, borderRadius: 4}, className: "bg-[#F7F7F7] dark:bg-trov-dark dark:text-white"}} open={open} onClose={() => setOpen(false)}>
				<DialogTitle className="text-center">
					<h3 className='dialog-title '>Liquidate Fund</h3>
					<h3 className='dialog-sub'>Current Balance - {(wallet === 'naira' ? `₦ ${formatNum(cash_balance?.customerNairaBalance)}` : `$ ${formatNum(cash_balance?.customerDollarBalance)}`)}</h3>
				</DialogTitle>
				<form onSubmit={submit}>
					<DialogContent>
						<div className='dark:text-white'>
							<div className='flex flex-col space-y-1 pb-4 w-full max-w-sm mx-auto'>
								<label className='text-sm font-semibold'>Amount</label>
								<div className='relative flex items-center '>
									<span className='absolute left-3'>
										<BankNoteIcon />
									</span>
									<CurrencyFormat thousandSeparator={true} prefix={wallet === 'naira' ? '₦ ' : '$ '} onValueChange={handleChange} placeholder='Enter Amount' className={`w-full border border-black dark:border-white rounded-full bg-transparent h-9 pr-4 pl-9 outline-none transition text-sm ${err && 'border-red-500'}`} />
								</div>
								{err && <div className='block max-w-sm text-red-500 text-xs'>{err}</div>}
							</div>
						</div>
					</DialogContent>
					<DialogActions sx={{justifyContent: 'center'}}>
						<CustomButton dark loading={inProgress} className="flex space-x-3 items-center w-full max-w-sm rounded-full mb-5 md:mb-7 justify-center" type="submit">
							<span>Submit</span>
							<RightArrIcon className="fill-white" />
						</CustomButton>
					</DialogActions>
				</form>
			</Dialog>
		</>
	)
}

export default RequestFund