import React from 'react'
import {Text, View, StyleSheet, Page, Document, Image, PDFDownloadLink, Font} from "@react-pdf/renderer";
import logo from '../assets/logo.png';
import {useSelector} from 'react-redux';
import {formatNum} from '../services/base';
import NotoSans from '../assets/NotoSans.ttf'
Font.register({family: 'Noto Sans', src: NotoSans});
const styles = StyleSheet.create({
	body: {
		padding: 30,
		fontSize: 10,
		fontFamily: 'Noto Sans',
	},
	table: {
		display: "table",
		width: '100%',
		borderStyle: "solid",
		borderWidth: 1,
		borderRightWidth: 0,
		borderBottomWidth: 0,
		borderColor: '#ccc',
	},
	acctTable: {
		display: "table",
		width: '50%',
		borderStyle: "solid",
		borderWidth: 1,
		borderRightWidth: 0,
		borderBottomWidth: 0,
		borderColor: '#ccc',
	},
	tableRow: {
		margin: "auto",
		flexDirection: "row",
		display: 'flex',
	},
	tableRowHead: {
		backgroundColor: '#ccc',
		margin: "auto",
		flexDirection: "row",
		display: 'flex',
	},
	tableCol: {
		borderStyle: "solid",
		borderWidth: 1,
		borderColor: '#ccc',
		borderLeftWidth: 0,
		borderTopWidth: 0,
	},
	tableCell: {
		margin: 5,
		fontWeight: 'bold'
	},
	logo: {
		width: 80,
		height: 40,
		border: '1 solid black'
	},
	head: {
		display: 'flex',
		flexDirection: 'column',
		gap: 15
	},
	footer: {
		border: '1 dotted #ccc',
		borderRightWidth: 0,
		borderLeftWidth: 0,
		paddingVertical: 10,
		gap: 2,
		fontSize: 7,
		display: 'flex',
		flexDirection: 'column'
	}
});

const CustomPDF = ({details, account}) => {
	const {data, customer
	} = useSelector(state => state.profile);
	const isCORP = (customer?.label === "CORP");
	return (
		<>
			<PDFDownloadLink document={
				<Document>
					<Page size={'A3'}>
						<View style={styles.body}>
							<View style={styles.head}>
								<Image src={logo} style={{width: 150, marginLeft: 'auto'}} />
								<Text style={{fontWeight: 'bold'}}>Transaction Statement as at {new Date().toDateString()}</Text>
								<View style={styles.acctTable}>
									{
										Object.keys(account).map(each => (
											<View style={styles.tableRow}>
												<View style={{...styles.tableCol, width: '30%'}}>
													<Text style={styles.tableCell}>{each}</Text>
												</View>
												<View style={{...styles.tableCol, width: '70%'}}>
													<Text style={styles.tableCell}>{account[each]}</Text>
												</View>
											</View>
										))
									}
								</View>

								<View style={{display: 'flex', flexDirection: 'column', textAlign: 'right'}}>
									<Text style={{fontWeight: 'bold', }}>
										CUSTOMER TRANSACTION STATEMENT
									</Text>
									<Text style={{fontWeight: 'bold', color: '#cb1c27'}}>
										{(isCORP ? data.business_name : `${data?.firstname} ${data?.lastname}`)?.toUpperCase()}
									</Text>
								</View>

								<View style={styles.table}>
									<View style={styles.tableRowHead}>
										<View style={{...styles.tableCol, width: '15%'}}>
											<Text style={styles.tableCell}>Transaction Date</Text>
										</View>
										<View style={{...styles.tableCol, width: '10%'}}>
											<Text style={styles.tableCell}>Transaction ID</Text>
										</View>
										<View style={{...styles.tableCol, width: '10%'}}>
											<Text style={styles.tableCell}>Currency</Text>
										</View>
										<View style={{...styles.tableCol, width: '15%'}}>
											<Text style={styles.tableCell}>Action</Text>
										</View>
										<View style={{...styles.tableCol, width: '15%'}}>
											<Text style={styles.tableCell}>Amount</Text>
										</View>
										<View style={{...styles.tableCol, width: '10%'}}>
											<Text style={styles.tableCell}>Status</Text>
										</View>
										<View style={{...styles.tableCol, width: '25%'}}>
											<Text style={styles.tableCell}>Remark</Text>
										</View>
									</View>
									{
										details.map(each => (
											<View style={styles.tableRow}>
												<View style={{...styles.tableCol, width: '15%'}}>
													<Text style={styles.tableCell}>{new Date(each.created_at).toDateString()}</Text>
												</View>
												<View style={{...styles.tableCol, width: '10%'}}>
													<Text style={styles.tableCell}>{each.tran_id}</Text>
												</View>
												<View style={{...styles.tableCol, width: '10%'}}>
													<Text style={styles.tableCell}>{each.currency}</Text>
												</View>
												<View style={{...styles.tableCol, width: '15%'}}>
													<Text style={styles.tableCell}>{each.action}</Text>
												</View>
												<View style={{...styles.tableCol, width: '15%'}}>
													<Text style={styles.tableCell}>{each.currency === 'USD' ? '$' : '₦'} {formatNum(each.amount)}</Text>
												</View>
												<View style={{...styles.tableCol, width: '10%'}}>
													<Text style={{
														...styles.tableCell, color: each.status.toLowerCase() === "pending" ? 'orange' :
															each.status.toLowerCase() === "approved" ? 'green' :
																each.status.toLowerCase() === "rejected" ? 'red' :
																	'#000'
														, textTransform: 'capitalize'
													}}>{each.status.toLowerCase()}</Text>
												</View>
												<View style={{...styles.tableCol, width: '25%'}}>
													<Text style={styles.tableCell}>{each.system_message}</Text>
												</View>
											</View>
										))
									}
								</View>
								<Text style={{fontSize: 7}}>-</Text>
								<Text style={{margin: 'auto', fontSize: 7}}>** This is an electronically generated certificate and requires no signature. **</Text>
							</View>
						</View>
					</Page>
				</Document>
			} fileName={`Account Statement`} className=''>
				Download Statement
			</PDFDownloadLink>
		</>
	)
}

export default CustomPDF