import {AppBar, IconButton, Toolbar} from '@mui/material'
import React from 'react'
import {Link} from 'react-router-dom'
import profileImg from '../assets/profile.png'
import {useSelector} from 'react-redux'

const CustomAppBar = ({drawerWidth, handleDrawerToggle}) => {
	const {customer} = useSelector(state => state.profile);

	return (
		<AppBar
			position="fixed"
			elevation={0}
			color="inherit"
			className='dark:bg-trov-dark'
			sx={{
				width: {sm: `calc(100% - ${drawerWidth}px)`},
				ml: {sm: `${drawerWidth}px`},
				// background: '#000',
				padding: 3,
				// zIndex: -1
			}}
		>
			<Toolbar sx={{paddingX: 0}}>
				<IconButton
					aria-label="open drawer"
					edge="start"
					onClick={handleDrawerToggle}
					sx={{mr: 2, display: {sm: 'none'}}}
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path className='fill-lack dark:fill-white' d="M4 11h12v2H4zm0-5h16v2H4zm0 12h7.235v-2H4z"></path></svg>
				</IconButton>
				<div className="flex w-full items-center max-w-[70rem] mx-auto">
					{/* <div className='bg-[#F6F6F6] dark:border dark:bg-transparent dark:border-[#E8E8E84D] p-3 text-sm items-center space-x-2 rounded-full w-full max-w-sm hidden md:flex'>
						<SearchIcon />
						<input className='outline-none bg-transparent' placeholder='Search' />
					</div> */}
					<div className='w-fit ml-auto flex space-x-3'>
						{/* <div className='h-10 w-10 relative flex items-center justify-center border border-dark rounded-full'>
							<NotificationIcon />
							<div className='bg-primary absolute top-0 right-1 rounded-full h-2 w-2'></div>
						</div> */}
						<Link to="/profile" className="cursor-pointer ml-auto flex space-x-4 items-center">
							<div className="bg-gray-400 h-10 w-10 rounded-full flex items-center justify-center">
								<img className='rounded-full h-full w-full object-cover' alt='profileimage' src={customer.passport ?? profileImg} onError={(e) => {e.target.src = profileImg}} />
							</div>
						</Link>
					</div>
				</div>
			</Toolbar>
		</AppBar>
	)
}

export default CustomAppBar