import React from 'react'
import {Text, View, StyleSheet, Page, Document, Image, PDFDownloadLink, Font} from "@react-pdf/renderer";
import logo from '../assets/logo.png';
import signature1 from '../assets/opmgsig.png';
import signature2 from '../assets/mgdirectorsig.png';
import {useSelector} from 'react-redux';
import NotoSans from '../assets/NotoSans.ttf'
Font.register({family: 'Noto Sans', src: NotoSans});
const styles = StyleSheet.create({
	body: {
		paddingVertical: 30,
		paddingHorizontal: 100,
		fontSize: 10,
		fontFamily: 'Noto Sans',
	},
	table: {
		display: "table",
		width: '100%',
	},
	tableRow: {
		margin: "auto",
		flexDirection: "row",
		display: 'flex'
	},
	tableCol: {
	},
	tableCell: {
		margin: 3,
		fontWeight: 'bold'
	},
	head: {
		display: 'flex',
		flexDirection: 'column',
		gap: 15
	},
	footer: {
		// border: '1 dotted #ccc',
		// borderRightWidth: 0,
		// borderLeftWidth: 0,
		paddingVertical: 10,
		gap: 2,
		fontSize: 8,
		display: 'flex',
		flexDirection: 'column',
		marginBottom: 5
	},
	sigCont: {
		display: 'flex',
		flexDirection: 'column',
		gap: 3,
		alignItems: 'center',
		fontSize: 8
	}
});

const CustomPDF = ({details, product}) => {
	const {data, customer
	} = useSelector(state => state.profile);
	const isCORP = (customer?.label === "CORP");
	return (
		<>
			<PDFDownloadLink document={
				<Document>
					<Page>
						<View style={styles.body}>
							<View style={styles.head}>
								<Image src={logo} style={{width: 100}} />
								{/* <Text>Plot 1601, Adeola Hopewell Street, Victoria Island, Lagos.</Text>
								<Text style={{fontWeight: 'bold'}}>{new Date().toDateString()}</Text> */}
								<View style={{display: 'flex', flexDirection: 'column', fontWeight: 'bold', textTransform: 'capitalize'}}>
									{/* <Text style={{fontWeight: 'bold', }}>
										{(isCORP ? data.business_name : `${data?.firstname} ${data?.lastname}`)?.toUpperCase()}
									</Text> */}
									<Text>{data?.address},</Text>
									<Text>{data?.city}, {data?.state}.</Text>
								</View>
								<View style={{display: 'flex', flexDirection: 'column', gap: 8}}>
									<Text>
										{(isCORP ? data.business_name : `Dear ${data?.firstname}`)},
									</Text>
									<Text style={{fontWeight: 'bold', textAlign: 'center', textDecoration: 'underline'}}>FIXED INVESTMENT CERTIFICATE</Text>
									{/* <Text>We confirm your investment with us under the following terms and conditions:</Text> */}
								</View>
								<View style={styles.table}>
									{
										details.map(each => (
											<View style={styles.tableRow}>
												<View style={{...styles.tableCol, width: '50%'}}>
													<Text style={styles.tableCell}>{each.title}</Text>
												</View>
												<View style={{...styles.tableCol, width: '50%'}}>
													<Text style={{...styles.tableCell, textTransform: 'capitalize'}}>: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{each.value}</Text>
												</View>
											</View>
										))
									}
								</View>
								{/* <View style={{display: 'flex', flexDirection: 'column', gap: 8}}>
								<Text>Yours faithfully,</Text>
								<Text style={{fontWeight: 'bold'}}>MUTESWAN CAPITAL LIMITED</Text>
							</View> */}
								<View style={styles.footer}>
									<Text style={{textDecoration: 'underline'}}>PLEASE NOTE</Text>
									<Text>* Your payment of the funds for Investment and our issue of this Certificate indicates our mutual agreement with the contract terms.</Text>
									<Text> * Where the Investment booking details does not agree with your records, kindly contact our customer care department at <Text style={{color: 'blue'}}>customercare@muteswancapital.com</Text> or your Relationship Manager immediately for regularization.</Text>
									<Text> * Kindly confirm to us your rollover instructions on or before maturity if there is any change. </Text>
									<Text>* Upon pre - liquidation of this investment, interest shall be calculated at a penal rate to be determined from time to time. </Text>
									<Text>* Mute Swan Capital also reserves the right to adjust the rates upwards or downwards in line with market realities. * </Text>
									<Text> * Pre - liquidation of investment will be treated within 48 hours from the date the liquidation request is received. *</Text>
								</View>
								<View style={{display: 'flex', gap: 8, flexDirection: 'row', justifyContent: 'space-between'}}>
									<View style={styles.sigCont}>
										<Image src={signature1} style={{height: 40, width: 80, paddingBottom: 5, borderBottom: '1 solid black'}} />
										<Text>Funds & Operational Manager</Text>
									</View>
									<View style={styles.sigCont}>
										<Image src={signature2} style={{height: 40, width: 80, paddingBottom: 5, borderBottom: '1 solid black'}} />
										<Text>Managing Director / CEO</Text>
									</View>
								</View>
							</View>
						</View>
					</Page>
				</Document>
			} fileName={`${product.name} certificate`} className='text-blue-400 text-xs'>
				Download Certificate
			</PDFDownloadLink>
		</>
	)
}

export default CustomPDF