import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import './App.css';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Dashboard from './Pages/user/Dashboard';
import Transactions from './Pages/user/Transactions';
import {createTheme, ThemeProvider} from '@mui/material';
import CashBalance from './Pages/user/CashBalance';
import Investment from './Pages/user/Investment';
import Profile from './Pages/user/Profile';
import IndividualReg from './components/Register/IndividualReg';
import CoporateReg from './components/Register/CoporateReg';
import "react-toastify/dist/ReactToastify.css";
import UserGuard from './Guards/UserGuard'
import {ToastContainer} from 'react-toastify';
import axios from 'axios';
import {Provider} from 'react-redux';
import store from './store';
import ForgotPassword from './Pages/ForgotPassword';
import ResetPassword from './Pages/ResetPassword';
import LoaderWrap from './components/LoaderWrap';
import EachInvestment from './Pages/user/EachInvestment';
import PersonalDetails from './Pages/user/profile/PersonalDetails';
import BanksAndCards from './Pages/user/profile/BanksAndCards';
import EmploymentDetails from './Pages/user/profile/EmploymentDetails';
import DocumentUpload from './Pages/user/profile/Documents';

axios.interceptors.request.use((value) => {
  value.headers = {
    "Authorization": `Bearer ${localStorage.token}`
  };
  return value;
})

const theme = createTheme({
  typography: {
    fontFamily: [
      'Manrope',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
  }
});

function App() {

  return (
    <Provider store={store}>
      <div className='font-serif'>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route key="guarded" path='' element={<UserGuard />} children={[
                <Route key="main" path='' element={<Navigate to="/dashboard" />} />,
                <Route key="dashboard" path='dashboard' element={<Dashboard />} />,
                <Route key="cash-bal" path='wallet' element={<CashBalance />} />,
                <Route key="investment" path='investment' element={<Investment />} />,
                <Route key="each_investment" path='investment/:investment_id' element={<EachInvestment />} />,
                <Route key="trx" path='transactions' element={<Transactions />} />,
                <Route key="profile" path='profile' element={<Profile />} />,
                <Route key="personalprofile" path='profile/personal' element={<PersonalDetails />} />,
                <Route key="banks" path='profile/banks' element={<BanksAndCards />} />,
                <Route key="employment" path='profile/others' element={<EmploymentDetails />} />,
                <Route key="DocumentUpload" path='profile/docs' element={<DocumentUpload />} />,
              ]} />
              <Route key="auth" path='' element={<LoaderWrap />} children={[
                <Route key="register" path='/register' element={<Register />} children={[
                  <Route key="index" path='' element={<Navigate to="individual" />} />,
                  <Route key="individual" path='individual' element={<IndividualReg />} />,
                  <Route key="corporate" path='corporate' element={<CoporateReg />} />,
                ]} />,
                <Route key="login" path='/login' element={<Login />} />,
                <Route key="forget" path='/forgot-password' element={<ForgotPassword />} />,
                <Route key="reset" path='/reset-password' element={<ResetPassword />} />
              ]} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
        <ToastContainer
          toastClassName={({type}) => "relative sm:mt-5 sm:right-10 min-h-10 w-screen sm:w-[20rem] text-trov-dark dark:text-white rounded-2xl shadow bg-white dark:bg-dark justify-between flex overflow-hidden cursor-pointer"
          }
          bodyClassName={() => "text-sm font-serif py-3 px-6 flex"}
          progressClassName={'absolute bottom-o'}
          position="top-right"
          closeButton={false}
          autoClose={3000}
        />
      </div>
    </Provider>
  );
}

export default App;
