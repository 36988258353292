import {Box, CssBaseline, Drawer, Toolbar} from '@mui/material';
import React, {useCallback, useEffect} from 'react'
import {Outlet} from 'react-router';
import CustomAppBar from '../../components/CustomAppBar';
import SideNav from '../../components/SideNav'
// import DashboardBgPattern from '../../components/Patterns/DashboardBgPattern'
import {corporateAccountInfoInputs, corporateContactInfoInputs, corporatePersonalInfoInputs, getDefault, individualAccountInfoInputs, individualContactInfoInputs, individualPersonalInfoInputs} from '../../services/reg-inputs'
import {setProfileCompletion} from '../../slices/userSlice';
import {useDispatch, useSelector} from 'react-redux';

const drawerWidth = 260;

const Main = (props) => {
	const {data, customer} = useSelector(state => state.profile);
	const isCORP = (customer?.label === "CORP");

	const [mobileOpen, setMobileOpen] = React.useState(false);
	// const classes = useStyles();
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const isComplete = useCallback((corp, indi) => {
		const details = getDefault((isCORP ? corp : indi), {...data, ...customer})
		let complete = true;
		Object.keys(details).forEach(each => {
			if (!details[each]) {
				complete = false;
			}
		})
		return complete;
	}, [data, customer, isCORP])

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(setProfileCompletion({
			personal: isComplete(corporatePersonalInfoInputs, individualPersonalInfoInputs),
			employment: isComplete(corporateContactInfoInputs, individualContactInfoInputs),
			banks: isComplete(corporateAccountInfoInputs, individualAccountInfoInputs),
			docs: (customer.id_card && customer.signature)
		}))
	}, [isComplete, customer, dispatch])

	return (
		<Box sx={{display: 'flex'}}>
			<CssBaseline />
			<CustomAppBar handleDrawerToggle={handleDrawerToggle} drawerWidth={drawerWidth} />
			<Box
				component="nav"
				sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}, }}
				aria-label="mailbox folders"
				className='bg-[#18191A] min-h-screen'
			>
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Drawer
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: {xs: 'block', sm: 'none'},
						'& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth, borderWidth: 0, background: '#18191A'},
					}}
				>
					<SideNav handleDrawerToggle={handleDrawerToggle} />
				</Drawer>
				<Drawer
					variant="permanent"
					sx={{
						display: {xs: 'none', sm: 'block'},
						'& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth, borderWidth: 0, background: '#18191A'},
					}}
					open
				>
					<SideNav />
				</Drawer>
			</Box>
			<Box
				component="main"
				sx={{flexGrow: 1, paddingY: 6, width: {sm: `calc(100% - ${drawerWidth}px)`}}}
			>
				{/* <DashboardBgPattern /> */}
				<div className='px-5 md:px-12 relative w-full'>
					<Toolbar />
					<div className='relative max-w-[70rem] mx-auto'>
						<Outlet />
					</div>
				</div>
			</Box>
		</Box >
	)
}

export default Main